body.dark-mode {
  background: #000000;
  background-image: linear-gradient(to bottom, #000000, #000000 80vh, #000000 180vh, #000000); }
  body.dark-mode ::-ms-reveal {
    filter: invert(100%); }
  body.dark-mode .anchor-jump.highlight {
    color: #5e0751; }
  body.dark-mode .flyout-background-cover {
    background-color: rgba(155, 155, 155, 0.2); }
  body.dark-mode input[type=number], body.dark-mode input[type=text], body.dark-mode input[type=email], body.dark-mode input[type=password], body.dark-mode input[type=pre-text], body.dark-mode input[type=tel], body.dark-mode textarea {
    background-color: #333333;
    color: #FFFFFF; }
    body.dark-mode input[type=number]::-moz-placeholder, body.dark-mode input[type=text]::-moz-placeholder, body.dark-mode input[type=email]::-moz-placeholder, body.dark-mode input[type=password]::-moz-placeholder, body.dark-mode input[type=pre-text]::-moz-placeholder, body.dark-mode input[type=tel]::-moz-placeholder, body.dark-mode textarea::-moz-placeholder {
      color: #FFFFFF; }
    body.dark-mode input[type=number]::placeholder, body.dark-mode input[type=text]::placeholder, body.dark-mode input[type=email]::placeholder, body.dark-mode input[type=password]::placeholder, body.dark-mode input[type=pre-text]::placeholder, body.dark-mode input[type=tel]::placeholder, body.dark-mode textarea::placeholder {
      color: #FFFFFF; }
  body.dark-mode .raform-outer .input-group-text {
    background-color: #333333;
    color: #FFFFFF; }
  body.dark-mode .raform-outer .form-group label {
    color: #FFFFFF; }
  body.dark-mode .dropdown-toggle {
    background-color: #333333;
    color: #FFFFFF; }
  body.dark-mode .dropdown-menu {
    background-color: #333333; }
    body.dark-mode .dropdown-menu ul li a {
      background-color: #333333;
      color: #FFFFFF; }
      body.dark-mode .dropdown-menu ul li a:hover, body.dark-mode .dropdown-menu ul li a.active {
        background-color: #575757; }
  body.dark-mode #header {
    background-color: #000000 !important;
    box-shadow: none !important;
    color: #FFFFFF; }
    body.dark-mode #header a:not(.btn), body.dark-mode #header a span {
      color: #FFFFFF; }
  body.dark-mode #header #mainnav .navbar-toggler.main-menu-toggler {
    color: #FFFFFF; }
  body.dark-mode #header .header-box .basketFlyout .minibasketButtons, body.dark-mode #header #topbar {
    background-color: #333333; }
  body.dark-mode #header .header-dropdown-menu, body.dark-mode #header .basketFlyout {
    background-color: #000000; }
    body.dark-mode #header .header-dropdown-menu hr, body.dark-mode #header .basketFlyout hr {
      border-top: 1px solid #FFFFFF; }
  body.dark-mode .flyout-menu-app svg path, body.dark-mode .categoryBox svg path {
    fill: #FFFFFF; }
  body.dark-mode #header .flyout-menu-app.flyout-menu-compact .menu, body.dark-mode #header .flyout-menu-app.flyout-menu-compact .menu-inner {
    background-color: #000000;
    border: 1px solid #FFFFFF; }
  body.dark-mode #header #topbar .languages-menu ul, body.dark-mode #header #topbar .currencies-menu ul {
    background-color: #333333; }
    body.dark-mode #header #topbar .languages-menu ul li:hover, body.dark-mode #header #topbar .currencies-menu ul li:hover {
      background-color: #575757; }
  body.dark-mode #header #topbar .languages-menu .dropdown-menu a, body.dark-mode #header #topbar .currencies-menu .dropdown-menu a {
    color: #FFFFFF; }
  body.dark-mode #header #mainnav #navigation-outer li a {
    color: #FFFFFF; }
  body.dark-mode .main-container {
    background-color: #000000; }
    body.dark-mode .main-container .mobile-menu-app {
      background-color: #000000; }
      body.dark-mode .main-container .mobile-menu-app .mobile-header {
        background-color: #000000;
        color: #FFFFFF; }
      body.dark-mode .main-container .mobile-menu-app #mobile-menu-countryselection, body.dark-mode .main-container .mobile-menu-app #mobile-menu-directorder, body.dark-mode .main-container .mobile-menu-app #mobile-menu-newproducts, body.dark-mode .main-container .mobile-menu-app #mobile-menu-saleproducts, body.dark-mode .main-container .mobile-menu-app .mobile-menu-language-list, body.dark-mode .main-container .mobile-menu-app .mobile-menu-currency-list {
        background-color: #333333; }
      body.dark-mode .main-container .mobile-menu-app .mobile-menu-list ul {
        background-color: #333333; }
      body.dark-mode .main-container .mobile-menu-app .mobile-menu-top-btn {
        background-color: #333333; }
    body.dark-mode .main-container .accountLoginView #account-login {
      color: #FFFFFF; }
    body.dark-mode .main-container .accountLoginView #account-register {
      background-color: #333333; }
      body.dark-mode .main-container .accountLoginView #account-register li {
        color: #FFFFFF; }
    body.dark-mode .main-container #searchFilter {
      background-color: #000000; }
    body.dark-mode .main-container a:not(.btn):not(.highlight):not(.link-overlay):not(.t-highlight):not(.fontunderline) {
      color: #FFFFFF; }
    body.dark-mode .main-container h1, body.dark-mode .main-container h2, body.dark-mode .main-container h3, body.dark-mode .main-container h4, body.dark-mode .main-container p:not(.alert-danger), body.dark-mode .main-container .agb a {
      color: #FFFFFF; }
    body.dark-mode .main-container .slider-mode-light p {
      color: #575757; }
    body.dark-mode .main-container .box1, body.dark-mode .main-container .box2, body.dark-mode .main-container .box3, body.dark-mode .main-container .box4, body.dark-mode .main-container .box5, body.dark-mode .main-container .box-text {
      color: #FFFFFF; }
      body.dark-mode .main-container .box1 p, body.dark-mode .main-container .box2 p, body.dark-mode .main-container .box3 p, body.dark-mode .main-container .box4 p, body.dark-mode .main-container .box5 p, body.dark-mode .main-container .box-text p {
        color: #FFFFFF; }
      body.dark-mode .main-container .box1 h1, body.dark-mode .main-container .box1 h2, body.dark-mode .main-container .box1 h3, body.dark-mode .main-container .box1 h4, body.dark-mode .main-container .box1 p, body.dark-mode .main-container .box2 h1, body.dark-mode .main-container .box2 h2, body.dark-mode .main-container .box2 h3, body.dark-mode .main-container .box2 h4, body.dark-mode .main-container .box2 p, body.dark-mode .main-container .box3 h1, body.dark-mode .main-container .box3 h2, body.dark-mode .main-container .box3 h3, body.dark-mode .main-container .box3 h4, body.dark-mode .main-container .box3 p, body.dark-mode .main-container .box4 h1, body.dark-mode .main-container .box4 h2, body.dark-mode .main-container .box4 h3, body.dark-mode .main-container .box4 h4, body.dark-mode .main-container .box4 p, body.dark-mode .main-container .box5 h1, body.dark-mode .main-container .box5 h2, body.dark-mode .main-container .box5 h3, body.dark-mode .main-container .box5 h4, body.dark-mode .main-container .box5 p, body.dark-mode .main-container .box-text h1, body.dark-mode .main-container .box-text h2, body.dark-mode .main-container .box-text h3, body.dark-mode .main-container .box-text h4, body.dark-mode .main-container .box-text p {
        color: #FFFFFF; }
    body.dark-mode .main-container .product-item .pricebox .price, body.dark-mode .main-container .product-item .pricebox .unit {
      color: #FFFFFF; }
    body.dark-mode .main-container .boxtitle {
      color: #FFFFFF; }
    body.dark-mode .main-container #sidebar #categories ul li .item-count {
      color: #FFFFFF;
      background-color: #333333;
      opacity: 1; }
    body.dark-mode .main-container .more-filter {
      color: #FFFFFF; }
    body.dark-mode .main-container #orderRemark {
      background-color: #333333;
      border: 1px solid #FFFFFF;
      color: #FFFFFF; }
      body.dark-mode .main-container #orderRemark::-moz-placeholder {
        color: #FFFFFF; }
      body.dark-mode .main-container #orderRemark::placeholder {
        color: #FFFFFF; }
  body.dark-mode.cl-forgotpwd #content form {
    background: #000000;
    border: 1px solid #e3e3e3; }
    body.dark-mode.cl-forgotpwd #content form label {
      color: #FFFFFF; }
    body.dark-mode.cl-forgotpwd #content form input[type=text] {
      background-color: #333333;
      color: #FFFFFF; }
  body.dark-mode #footer.darkFooter .container-fluid {
    background-color: #000000; }
    body.dark-mode #footer.darkFooter .container-fluid .information-bar {
      background-color: #333333;
      color: #FFFFFF; }
    body.dark-mode #footer.darkFooter .container-fluid .trustedShopsLogo {
      color: #FFFFFF; }
  body.dark-mode #footer .container-fluid {
    background-color: #000000; }
    body.dark-mode #footer .container-fluid .service-container, body.dark-mode #footer .container-fluid .additional-information, body.dark-mode #footer .container-fluid .social-media-container, body.dark-mode #footer .container-fluid .legal-notice-container, body.dark-mode #footer .container-fluid .carrier-info-container {
      color: #FFFFFF; }
      body.dark-mode #footer .container-fluid .service-container a:not(.btn), body.dark-mode #footer .container-fluid .additional-information a:not(.btn), body.dark-mode #footer .container-fluid .social-media-container a:not(.btn), body.dark-mode #footer .container-fluid .legal-notice-container a:not(.btn), body.dark-mode #footer .container-fluid .carrier-info-container a:not(.btn) {
        color: #FFFFFF; }
      body.dark-mode #footer .container-fluid .service-container h2, body.dark-mode #footer .container-fluid .service-container h4, body.dark-mode #footer .container-fluid .additional-information h2, body.dark-mode #footer .container-fluid .additional-information h4, body.dark-mode #footer .container-fluid .social-media-container h2, body.dark-mode #footer .container-fluid .social-media-container h4, body.dark-mode #footer .container-fluid .legal-notice-container h2, body.dark-mode #footer .container-fluid .legal-notice-container h4, body.dark-mode #footer .container-fluid .carrier-info-container h2, body.dark-mode #footer .container-fluid .carrier-info-container h4 {
        color: #FFFFFF; }
    body.dark-mode #footer .container-fluid .newsletter-widget button {
      border: 1px solid #FFFFFF; }
  body.dark-mode #footer .information-bar {
    background-color: #333333; }
  body.dark-mode #footer .checkout-footer-info a {
    color: #FFFFFF; }
    body.dark-mode #footer .checkout-footer-info a:hover {
      color: #f6f6f6; }
  body.dark-mode #footer .social-media-container i:not(:hover) {
    color: #FFFFFF; }
  body.dark-mode #footer #mobileContactBox {
    background-color: #000000; }
    body.dark-mode #footer #mobileContactBox button.btn-element {
      color: #FFFFFF; }
  body.dark-mode #footer .additional-information #accordion .card-header button {
    color: #FFFFFF; }
    body.dark-mode #footer .additional-information #accordion .card-header button.collapsed {
      border-bottom: 1px solid #FFFFFF; }
  @media (max-width: 767.98px) {
    body.dark-mode #footer .search-container {
      background-color: #000000; } }
  body.dark-mode #Basket #basket_list .deliverytime .ra-icon {
    color: #FFFFFF; }
  body.dark-mode #Basket .basketItem .totalPrice {
    color: #FFFFFF; }
  body.dark-mode #Basket .basketItem .deltime-and-buttons {
    color: #FFFFFF; }
  body.dark-mode .checkoutOptions #checkout-ssl-info {
    color: #FFFFFF; }
  body.dark-mode .checkoutOptions .checkout-login {
    color: #FFFFFF; }
    body.dark-mode .checkoutOptions .checkout-login h3, body.dark-mode .checkoutOptions .checkout-login h4 {
      color: #FFFFFF; }
  body.dark-mode #optionNoRegistration {
    color: #FFFFFF; }
    body.dark-mode #optionNoRegistration h3, body.dark-mode #optionNoRegistration h4 {
      color: #FFFFFF; }
  body.dark-mode #checkout-user-change {
    color: #FFFFFF; }
    body.dark-mode #checkout-user-change h1, body.dark-mode #checkout-user-change h2, body.dark-mode #checkout-user-change h3, body.dark-mode #checkout-user-change h4 {
      color: #FFFFFF; }
  body.dark-mode #breadcrumb .ra-icon {
    color: #FFFFFF; }
  body.dark-mode #breadcrumb .breadcrumb-element:before {
    color: #FFFFFF; }
  body.dark-mode #breadcrumb .breadcrumb-element .breadcrumb-text {
    color: #FFFFFF; }
  body.dark-mode #details_container {
    color: #FFFFFF; }
    body.dark-mode #details_container .table {
      color: #FFFFFF; }
      body.dark-mode #details_container .table tbody tr:nth-of-type(odd) {
        background-color: #333333; }
      body.dark-mode #details_container .table tr:hover, body.dark-mode #details_container .table tr:hover td {
        background-color: #333333; }
    body.dark-mode #details_container .details-sidebar {
      background-color: #333333;
      color: #FFFFFF; }
      body.dark-mode #details_container .details-sidebar a {
        color: #FFFFFF; }
      body.dark-mode #details_container .details-sidebar .details-contact a {
        color: #5e0751; }
      body.dark-mode #details_container .details-sidebar .details-contact div.ContactAsTeam a {
        color: #FFFFFF; }
      body.dark-mode #details_container .details-sidebar .ra-details-reset-variants-button {
        color: #FFFFFF; }
        body.dark-mode #details_container .details-sidebar .ra-details-reset-variants-button:hover {
          color: #e3e3e3; }
          body.dark-mode #details_container .details-sidebar .ra-details-reset-variants-button:hover .ra-icon {
            color: #e3e3e3; }
      body.dark-mode #details_container .details-sidebar .details-contact span {
        color: #FFFFFF; }
      body.dark-mode #details_container .details-sidebar .popover-body {
        background-color: #000000;
        color: #FFFFFF; }
        body.dark-mode #details_container .details-sidebar .popover-body a {
          color: #FFFFFF; }
    body.dark-mode #details_container .tabs-bar-background {
      background-color: #333333; }
    body.dark-mode #details_container .tabs-bar {
      background-color: #333333; }
      body.dark-mode #details_container .tabs-bar a {
        color: #FFFFFF; }
    body.dark-mode #details_container .ra-details-scale-prices {
      background-color: #000000; }
  body.dark-mode .middle-text:before {
    background-color: #FFFFFF; }
  body.dark-mode .middle-text:after {
    color: #FFFFFF;
    background-color: #333333 !important; }
  body.dark-mode .checkout-payment {
    color: #FFFFFF; }
    body.dark-mode .checkout-payment #expressDeliveryBox {
      color: #575757; }
  body.dark-mode .checkout-order, body.dark-mode .checkout-thankyou {
    color: #FFFFFF; }
    body.dark-mode .checkout-order h1, body.dark-mode .checkout-order h2, body.dark-mode .checkout-order h3, body.dark-mode .checkout-order h4, body.dark-mode .checkout-thankyou h1, body.dark-mode .checkout-thankyou h2, body.dark-mode .checkout-thankyou h3, body.dark-mode .checkout-thankyou h4 {
      color: #FFFFFF; }
    body.dark-mode .checkout-order .error h1, body.dark-mode .checkout-order .error h2, body.dark-mode .checkout-order .error h3, body.dark-mode .checkout-order .error h4, body.dark-mode .checkout-order .error .panel-body, body.dark-mode .checkout-order .error .panel-body p, body.dark-mode .checkout-thankyou .error h1, body.dark-mode .checkout-thankyou .error h2, body.dark-mode .checkout-thankyou .error h3, body.dark-mode .checkout-thankyou .error h4, body.dark-mode .checkout-thankyou .error .panel-body, body.dark-mode .checkout-thankyou .error .panel-body p {
      color: #575757; }
    body.dark-mode .checkout-order .error a, body.dark-mode .checkout-thankyou .error a {
      color: #5e0751; }
    body.dark-mode .checkout-order div[class*="cw-payment-form"] .panel, body.dark-mode .checkout-thankyou div[class*="cw-payment-form"] .panel {
      background-color: #333333; }
      body.dark-mode .checkout-order div[class*="cw-payment-form"] .panel .panel-heading, body.dark-mode .checkout-order div[class*="cw-payment-form"] .panel .control-label, body.dark-mode .checkout-thankyou div[class*="cw-payment-form"] .panel .panel-heading, body.dark-mode .checkout-thankyou div[class*="cw-payment-form"] .panel .control-label {
        color: #FFFFFF; }
      body.dark-mode .checkout-order div[class*="cw-payment-form"] .panel div[class*="Wrapper"], body.dark-mode .checkout-thankyou div[class*="cw-payment-form"] .panel div[class*="Wrapper"] {
        background: none; }
  body.dark-mode .contact-box {
    background-color: #000000; }
    body.dark-mode .contact-box .contact-box-inner .btn {
      color: #FFFFFF; }
    body.dark-mode .contact-box .information-box {
      background-color: #000000;
      color: #FFFFFF; }
      body.dark-mode .contact-box .information-box .information-box-top-bar {
        background-color: #333333; }
      body.dark-mode .contact-box .information-box h2, body.dark-mode .contact-box .information-box h3, body.dark-mode .contact-box .information-box h4 {
        color: #FFFFFF; }
      body.dark-mode .contact-box .information-box a {
        color: #FFFFFF; }
  body.dark-mode .modal-content {
    background-color: #000000;
    color: #FFFFFF;
    border: 1px solid #FFFFFF; }
    body.dark-mode .modal-content h3, body.dark-mode .modal-content h4, body.dark-mode .modal-content h5, body.dark-mode .modal-content h6 {
      color: #FFFFFF; }
    body.dark-mode .modal-content a:not(.btn-lightgrey) {
      color: #FFFFFF; }
      body.dark-mode .modal-content a:not(.btn-lightgrey):hover {
        color: #e3e3e3; }
  body.dark-mode #loginModal .password-lost a {
    color: #FFFFFF; }
    body.dark-mode #loginModal .password-lost a:hover {
      color: #e3e3e3; }
  body.dark-mode #loginModal .bottom-area {
    background-color: #333333;
    color: #FFFFFF; }
  body.dark-mode .filters .filter .dropdown .btn-dropdown-filter {
    background-color: #000000;
    border-bottom-color: #FFFFFF;
    color: #FFFFFF !important; }
    body.dark-mode .filters .filter .dropdown .btn-dropdown-filter:focus {
      border-color: transparent; }
  body.dark-mode .filters .filter .dropdown.dropdown.show .btn-dropdown-filter {
    border-color: transparent !important; }
  body.dark-mode .filters .filter .dropdown .dropdown-menu {
    border-color: #FFFFFF; }
    body.dark-mode .filters .filter .dropdown .dropdown-menu ul {
      background-color: #000000;
      color: #FFFFFF; }
    body.dark-mode .filters .filter .dropdown .dropdown-menu .wrapper {
      background-color: #000000; }
      body.dark-mode .filters .filter .dropdown .dropdown-menu .wrapper .priceSlider {
        background-color: #FFFFFF; }
      body.dark-mode .filters .filter .dropdown .dropdown-menu .wrapper .noUi-handle {
        background-color: #333333; }
    body.dark-mode .filters .filter .dropdown .dropdown-menu .currency-symbol {
      background-color: #333333;
      color: #FFFFFF; }
    body.dark-mode .filters .filter .dropdown .dropdown-menu .checkbox {
      color: #FFFFFF; }
  body.dark-mode .btn-group.sort-group .btn-dropdown-sort, body.dark-mode .btn-group.sort-group li, body.dark-mode .btn-group.sort-group a {
    background-color: #000000; }
  body.dark-mode .btn-group.sort-group .btn-dropdown-sort {
    color: #FFFFFF !important; }
  body.dark-mode .btn-group.sort-group .dropdown-menu ul {
    background-color: #000000; }
  body.dark-mode #details_container .details-sidebar .price.ra-details-price span {
    color: #FFFFFF; }
  body.dark-mode .filter-option-inner-inner {
    color: #FFFFFF; }
  body.dark-mode .btn-group.pagination-group li {
    color: #FFFFFF; }
  body.dark-mode .mo-box-outer {
    background-color: #000000;
    border: 1px solid #FFFFFF;
    color: #FFFFFF; }
  body.dark-mode ul.directOrderForm .output.suggestions {
    background-color: #000000;
    bordeR: 1px solid #FFFFFF;
    color: #FFFFFF; }
  body.dark-mode ul.directOrderForm .directOrderLine {
    border-top: 1px solid #FFFFFF; }
  body.dark-mode ul.directOrderForm .artShortInfo {
    color: #FFFFFF; }
  body.dark-mode ul.directOrderForm #directOrderHeadings h4 {
    color: #FFFFFF; }
  body.dark-mode #basketModal .modal-dialog .modal-content .modal-body div.basketInfo {
    background-color: #333333; }
    body.dark-mode #basketModal .modal-dialog .modal-content .modal-body div.basketInfo .item-box {
      background-color: #000000; }
  body.dark-mode #basketSideBar div.content {
    background-color: #333333 !important;
    color: #FFFFFF; }
  body.dark-mode #Basket .ra-basket-comment-and-cost-centre, body.dark-mode #Basket .ra-basket-udws {
    background-color: #333333;
    color: #FFFFFF; }
    body.dark-mode #Basket .ra-basket-comment-and-cost-centre button, body.dark-mode #Basket .ra-basket-udws button {
      color: #FFFFFF; }
  body.dark-mode #Basket .couponBox h4 {
    color: #FFFFFF; }
  body.dark-mode #basket_list .itemAmount .btn-left, body.dark-mode #basket_list .itemAmount .btn-right {
    background-color: #333333 !important;
    color: #FFFFFF; }
  body.dark-mode #basket_list .unitPrice {
    color: #FFFFFF; }
  body.dark-mode #basket_list .removeButton {
    color: #FFFFFF; }
    body.dark-mode #basket_list .removeButton:hover {
      color: #dc371c; }
  body.dark-mode #basket_list .quantity label {
    color: #FFFFFF; }
  body.dark-mode #basket_list .deliverytime i {
    color: #FFFFFF; }
  body.dark-mode #basket_list hr {
    border-top: 1px solid #FFFFFF; }
  body.dark-mode .tooltip-inner {
    background-color: #000000;
    color: #FFFFFF;
    border: 1px solid #FFFFFF; }
  body.dark-mode .checkoutOptions .grey {
    background-color: #333333;
    color: #FFFFFF; }
  body.dark-mode .cancel-order-container a {
    color: #FFFFFF; }
    body.dark-mode .cancel-order-container a:hover {
      color: #f6f6f6; }
  body.dark-mode .checkoutCollumns hr {
    border-top: 1px solid #FFFFFF; }
  body.dark-mode #shipping-address-select button, body.dark-mode #shipping-address-select a {
    color: #333333; }
  body.dark-mode #shipping-address-select .btn-dark {
    border: 1px solid #FFFFFF;
    color: #FFFFFF; }
  body.dark-mode a.highlight {
    color: #5e0751; }
  body.dark-mode #suggester #suggest_layer {
    background-color: #333333; }
    body.dark-mode #suggester #suggest_layer::after {
      border-color: transparent transparent #333333 #333333; }
    body.dark-mode #suggester #suggest_layer .sugg_product_category_title .count {
      background-color: #000000;
      color: #FFFFFF; }
    body.dark-mode #suggester #suggest_layer .category-list li {
      background-color: #000000;
      border: 1px solid #000000; }
      body.dark-mode #suggester #suggest_layer .category-list li:hover {
        background-color: #333333;
        border: 1px solid #FFFFFF; }
    body.dark-mode #suggester #suggest_layer .category-list .show-more-element {
      background-color: #000000;
      border: 1px solid #000000;
      color: #FFFFFF; }
      body.dark-mode #suggester #suggest_layer .category-list .show-more-element:hover {
        border: 1px solid #FFFFFF; }
  body.dark-mode #jumptotop {
    background-color: rgba(227, 227, 227, 0.5); }
    body.dark-mode #jumptotop .icon, body.dark-mode #jumptotop .text {
      color: #575757; }
  body.dark-mode .checkout-thankyou .success-checkmark .check-icon:after, body.dark-mode .checkout-thankyou .success-checkmark .check-icon:before, body.dark-mode .checkout-thankyou .success-checkmark .icon-fix {
    background: #000000; }
  body.dark-mode .thankyou-seperator hr {
    border-top: 1px solid #FFFFFF; }
  body.dark-mode .thankyou-contact a {
    color: #FFFFFF; }
  body.dark-mode .main-container form#catorder-form div.catalog-item .catalog-item-info {
    background-color: #000000; }
  body.dark-mode .main-container form#catorder-form div.catalog-item .catalog-item-page-count {
    color: #FFFFFF; }
  body.dark-mode .main-container form#catorder-form .catalog-item-outer > .catalog-item-inner {
    box-shadow: -2rem 0 2rem rgba(51, 51, 51, 0.5); }
  body.dark-mode .main-container form#catorder-form #catorderDropBox {
    background-color: #333333; }
  body.dark-mode .main-container form#catorder-form .security-info {
    color: #FFFFFF; }
  body.dark-mode .security-info {
    color: #FFFFFF; }
  body.dark-mode .account_order_history_container .order_details h5 {
    color: #FFFFFF; }
  body.dark-mode #reviewArticle #chars, body.dark-mode #reviewArticle #ReviewHint {
    color: #FFFFFF; }
  body.dark-mode #Basket #basketSideBar .IsBelowMinOrderPrice .BelowMinOrderPriceNotice div.row {
    background-color: #000000 !important; }
  body.dark-mode #Basket #basket_list .removedArticle {
    background-color: #000000; }
  body.dark-mode #Basket #basket_list .unitDesc {
    color: #FFFFFF; }
  body.dark-mode .extra-box .information-box {
    background-color: #000000;
    color: #FFFFFF; }
  body.dark-mode .ra-consent-box-inner {
    position: absolute;
    left: 10%;
    top: 10%;
    width: 80%;
    height: 80%;
    padding: 10px;
    background-color: #333333;
    box-shadow: 0 0 2px #FFFFFF;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
  body.dark-mode .product-bestseller:hover {
    background-color: #000000 !important; }
  body.dark-mode .category-item-img-button:hover {
    background: #333333 !important; }
